import { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import { base_url } from "./components/Base_url";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import About from "./components/pages/About";
import Footer from "./components/Footer";
import Screenings from "./components/pages/Screenings";
import Soon from "./components/pages/Soon";
import PromoPage from "./components/pages/PromoPage";
import Contacts from "./components/pages/Contacts";
import ScreeningsDates from "./components/ScreeningsDates";
import Release from "./components/pages/Release";
import LoginForm from "./components/pages/LoginForm";
import Admin from "./components/pages/Admin";
import AdminPromo from "./components/pages/AdminPromo";
import EditPromo from "./components/pages/EditPromo";
import PromoDetail from "./components/pages/PromoDetail";
import AdminPremiere from "./components/pages/AdminPremiere";
import EditPremiere from "./components/pages/EditPremiere";
import SoonDetails from "./components/pages/SoonDetails";
import HallPlan from "./components/pages/HallPlan";
import AdminBackground from "./components/pages/AdminBackground";
import EditBackground from "./components/pages/EditBackground";
import { auth } from "./components/Auth";
import FailurePage from "./components/pages/FailurePage";
import SuccessPage from "./components/pages/SuccessPage";
import AdminAddress from "./components/pages/AdminAddress";
import EditAddress from "./components/pages/EditAddress";
import AdminPayments from "./components/pages/AdminPayments";
import EditPayments from "./components/pages/EditPayments";
import Error from "./components/pages/Error";
import AdminAdvertisements from "./components/pages/AdminAdvertisements";
import EditAdvertisement from "./components/pages/EditAdvertisemens";

const App = () => {
  const [imageUrl, setImageUrl] = useState<string | "">("");
  const [advertisementImageUrl, setAdvertisementImageUrl] = useState<string | "">("");
  const [showAdvertisement, setShowAdvertisement] = useState<boolean>(true);
  const fetchBackground = useCallback(() => {
    axios({
      url: base_url() + "/api/v1/admin/getActiveBackground",
      method: "GET",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {}
    }).then((response) => {
      const data = response.data;
      if (data.response.isActive === 1) {
        setImageUrl(`data:image/jpeg;base64,${data.response.img}`)
      }
    }).catch((err) => {
      const status = err.response.status;
      if (status === 401) {
        auth();
        fetchBackground();
      }
    });
  }, []);

  const fetchAdvertisements = useCallback(() => {
    axios({
      url: base_url() + "/api/v1/admin/getActiveAdvertisements",
      method: "GET",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {}
    }).then((response) => {
      if (response.status === 200) {
        const size = response.data.response.length;
        const randomData = response.data.response[Math.floor(Math.random() * size)];
        setAdvertisementImageUrl(`data:image/jpeg;base64,${randomData.img}`);
        setShowAdvertisement(true);
      }
    }).catch((err: any) => {
      if (err.response) {
        const status = err.response.status;
        if (status === 401) {
          auth();
          fetchBackground();
        }
      }
    });
  }, []);

  useEffect(() => {
    fetchBackground();

    if (imageUrl !== "") {
      document.body.style.backgroundImage = `url(${imageUrl})`;
      document.body.style.backgroundAttachment = "fixed";
      document.body.style.padding = "250px 0 0 0";
    }

    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundAttachment = "";
      document.body.style.padding = "";
      document.body.style.backgroundSize = "";
      document.body.style.backgroundPosition = "";
    };
  }, [imageUrl, fetchBackground]);

  useEffect(() => {
    fetchAdvertisements();
  }, [advertisementImageUrl, fetchAdvertisements]);

  function closeAdvertisement() {
    setShowAdvertisement(false);
  }

  return (
    <div className="app">
      <div className="wrapper">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<ScreeningsDates />}>
              <Route path="/" element={<Home />} />
              <Route path="/:date" element={<Home />} />
            </Route>

            <Route path="/release/*" element={<Release />} />
            <Route path="/about" element={<About />} />
            <Route path="/screenings" element={<ScreeningsDates />}>
              <Route path="/screenings/:date" element={<Screenings />} />
            </Route>
            <Route path="/soon" element={<Soon />} />
            <Route path="/soon/:id" element={<SoonDetails />} />
            <Route path="/promo" element={<PromoPage />} />
            <Route path="/promo/:id" element={<PromoDetail />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/hallplan/:date" element={<HallPlan />} />
            <Route path="/error" element={<Error />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/admin/index" element={<Admin />} />
            <Route path="/admin/admin_promo" element={<AdminPromo />} />
            <Route path="/admin/edit_promo/:id" element={<EditPromo />} />
            <Route path="/admin/admin_premiere" element={<AdminPremiere />} />
            <Route path="/admin/edit_premiere/:id" element={<EditPremiere />} />
            <Route path="/admin/admin_background" element={<AdminBackground />} />
            <Route path="/admin/edit_background/:id" element={<EditBackground />} />
            <Route path="/admin/admin_address" element={<AdminAddress />} />
            <Route path="/admin/edit_address/:id" element={<EditAddress />} />
            <Route path="/admin/admin_refund" element={<AdminPayments />} />
            <Route path="/admin/edit_refund/:id" element={<EditPayments />} />
            <Route path="/admin/advertisements" element={<AdminAdvertisements />} />
            <Route path="/admin/edit_advertisement/:id" element={<EditAdvertisement />} />
            <Route path="/payment_failure/*" element={<FailurePage />} />
            <Route path="/payment_success/*" element={<SuccessPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </div>
      {
        showAdvertisement && advertisementImageUrl ?
          <div style={{
            backgroundImage: `url(${advertisementImageUrl})`,
            backgroundRepeat: "no-repeat",
            width: '100%',
            height: '100px',
            position: 'fixed',
            bottom: 0,
            zIndex: 9
          }}>
            <div className="footer-close" onClick={closeAdvertisement}>
              <span className="fas fa-times"></span>
            </div>
          </div>
          : null
      }
    </div>
  );
}

export default App;
