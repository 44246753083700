import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { base_url } from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { MovieSessions } from "../../interfaces";
import { auth } from "../Auth";

const Screenings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [movie, setMovie] = useState<[] | MovieSessions[]>([]);
    const date = location.pathname.split('/')[2];

    const fetchScreenings = useCallback((date: string) => {
        const dates: string[] = [];
        dates.push(date)
        axios({
            url: base_url() + `/api/v1/getScreenings?dates=${dates}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setMovie(data.response);
            setIsLoading(false);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchScreenings(date);
            }
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchScreenings(date);
    }, [fetchScreenings, date]);

    const handleNavigation = (movie: MovieSessions) => {
        navigate(`/hallplan/${date}`, { state: { movie } })
    };

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    return (
        <>
            {
                movie.length > 0 ?
                    movie.map(item => (
                        <div className="daily-seance-item" onClick={() => handleNavigation(item)} key={item.sessionId}>
                            <div className="daily-seance-item__seance-time">
                                {item.time}
                            </div>
                            <div className="daily-seance-item__seance-info">
                                <div className="daily-seance-item__seance-title">{item.filmName}</div>
                                <div className="daily-seance-item__genres-age-info">
                                    <span>{item.age}+</span>
                                    <span>{item.genre}</span>
                                </div>
                                <div className="daily-seance-item__other-info">
                                    <span>{item.formatContent}</span>
                                    <span>
                                        {/* Взрослый - */}
                                        {
                                            item.getSessionPricesResponse.data.placesTypes.placeType.sum ?
                                                item.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100 : null
                                        } c
                                    </span>
                                    {/* <span> Студенческий -
                                {
                                    item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[1].sum ?
                                        item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[1].sum.sum / 100 : null
                                } c
                            </span>
                            <span> Детский -
                                {
                                    item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[0].sum ?
                                        item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[0].sum.sum / 100 : null
                                } c
                            </span> */}
                                    <span>{item.hallName}</span>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className="no-seanses">
                        <p className="no-seanses__title">Сеансов на сегодня нет</p>
                    </div>
            }
        </>
    );
}

export default Screenings;