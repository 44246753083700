import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { Premiere } from "../../interfaces/index";
import YouTube from 'react-youtube';
import { auth } from "../Auth";

const SoonDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [premiereDetails, setPremiereDetails] = useState<Premiere>();
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const [premiere, setPremiere] = useState<[] | Premiere[]>([]);

    useEffect(() => {
        const fetchPremiere = async () => {
            await axios({
                url: base_url() + "/api/v1/admin/getPremier",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                data: {}
            }).then((response) => {
                const data = response.data;
                setPremiere(data.response);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchPremiere();
                }
            });
        };
        fetchPremiere();
    }, [])

    useEffect(() => {
        setIsLoading(true);
        const fetchPremiereById = async (id: string) => {
            await axios({
                url: base_url() + "/api/v1/admin/getPremiereById",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                data: JSON.stringify({ id: id })
            }).then((response) => {
                const data = response.data;
                setPremiereDetails(data.response);
                setIsLoading(false);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchPremiereById(id);
                }
                setIsLoading(false);
            });
        };

        fetchPremiereById(id);
    }, [location]);

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    return (
        <>
            <div className='main'>
                <div key={premiereDetails?.id}>
                    <div className="breadcrumbs">
                        <div className="breadcrumbs-container container">
                            <a className="breadcrumbs__link" href="/">Расписание залов</a>
                            <span className="breadcrumbs__divider">»</span>
                            <span className="breadcrumbs__page">{premiereDetails?.filmName}</span>
                        </div>
                    </div>
                    <div className="release">
                        <div className="container release-container">
                            <div className="release-left">
                                <div className="release-poster ">
                                    <div className="release-poster__img-wrapper">
                                        <img src={`data:image/jpeg;base64,${premiereDetails?.img}`} className="promo-item__poster-img" alt={`Постер релиза ${premiereDetails?.filmName}`} />
                                    </div>
                                    <div className="release__age">{premiereDetails?.age}+</div>
                                    <div className="release__badge">
                                    </div>
                                </div>
                            </div>
                            <div className="release-right">
                                <div className="release-description">
                                    <div className="release__genre">
                                        <span style={{ marginRight: "10px" }}>{premiereDetails?.genres}</span>
                                    </div>
                                    <div className="release__title">{premiereDetails?.filmName}</div>
                                </div>
                                <div className="release__info" style={{ textAlign: "center" }}>
                                    В кино с {premiereDetails?.period}
                                </div><br />
                                {
                                    premiereDetails?.youtubeID ?
                                        <div className="release-trailer-list">
                                            <YouTube videoId={premiereDetails?.youtubeID} opts={{ width: "300", height: "150" }} />
                                        </div>
                                        : null
                                }
                                <div className="release__info">
                                    <div className="release__info-item">
                                        <span className="release__info-item__name">Режиссёр</span>
                                        <span className="release__info-item__description">{premiereDetails?.director}</span>
                                    </div>
                                    <div className="release__info-item">
                                        <span className="release__info-item__name">В ролях</span>
                                        <span className="release__info-item__description">{premiereDetails?.actors}</span>
                                    </div>
                                    <div className="release__info-item">
                                        <span className="release__info-item__name">Страна</span>
                                        <span className="release__info-item__description">{premiereDetails?.country}</span>
                                    </div>
                                    <div className="release__info-item">
                                        <span className="release__info-item__name">Год</span>
                                        <span className="release__info-item__description">{ }</span>
                                    </div>
                                </div>
                                <div className="release__text">
                                    <div className="release__info-item__name">Описание</div>
                                    {premiereDetails?.remark}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="releases-soon ">
                <div className="container releases-soon--title">
                    <h2 className="releases-list__title">Скоро в кино</h2>
                </div>
                <div className="container releases-container">
                    {premiere.map(item => (
                        item.id !== parseInt(id, 10) ?
                            <a className="releases-item releases-item_soon" href={`${item.id}`} key={item.id}>
                                <div className="releases-item__poster releases-item__poster_soon gradient_4">
                                    <div className="releases-item__poster-height"></div>
                                    <img src={`data:image/jpeg;base64,${item.img}`} className="promo-item__poster-img" alt={`Постер релиза ${item.filmName}`} />
                                    <div className="releases-item__age">{item.age}+</div>
                                    <div className="releases-item__badge"></div>
                                </div>
                                <div className="releases-item__info">
                                    <div className="releases-item__date-wrapper">
                                        <span className="releases-item__date">с {item.period}</span>
                                    </div>
                                    <div className="releases-item-description">
                                        <div className="releases-item-description__title releases-item-description__title_small">{item.filmName}</div>
                                    </div>
                                </div>
                            </a>
                            : null
                    ))}
                </div>
            </div>
        </>
    )
}
export default SoonDetails;
