import { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from 'react-router-dom';
import axios from "axios";
import { base_url } from "../components/Base_url";
import { Days } from "../interfaces";
import { auth } from "./Auth";

const ScreeningsDates = () => {
  let months = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июн.', 'июл.', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'];
  let weekDay = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  let shortWeekDay = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
  const [activeDates, setActiveDates] = useState('');
  const [link, setLink] = useState('');
  const [dates, setDates] = useState<[] | Days[]>([]);
  const [weekDays, setWeekDays] = useState<[] | string[]>([]);
  const [shortWeekDays, setShortWeekDays] = useState<[] | string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const url = location.pathname.split('/')[1];

  let currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();

  useEffect(() => {
    if (url === "screenings") {
      setActiveDates(location.pathname.split('/')[2]);
      setLink("screenings/");
    } else {
      setActiveDates(url === "" ? `${day}.${month}.${year}` : url);
      setLink("");
    }

    const fetchDates = async () => {
      await axios({
        url: base_url() + "/api/v1/getDays",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {}
      }).then((response) => {
        const data = response.data;
        listOfDates(data.response);
        setDates(data.response);
      }).catch((err) => {
        const status = err.response.status;
        if (status === 401) {
          auth();
          fetchDates();
        }
      });
    };

    fetchDates();
  }, [location, day, month, url, year]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listOfDates = (data: Days[]) => {
    const arr: string[] = [];
    const shortArr: string[] = [];
    arr.push("Сегодня");
    arr.push("Завтра");
    for (let i = 0; i < data.length; i++) {
      let el = data[i];
      const dateParts = el.date.split(".");
      const year = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[0]);
      const date = new Date(year, month, day);
      let dayStr = weekDay[date.getDay()] + ', ' + (date.getDate()) + ' ' + months[date.getMonth()];
      if (i >= 2) {
        arr.push(dayStr);
      }

      let shortDayStr = shortWeekDay[date.getDay()] + (date.getDate());
      shortArr.push(shortDayStr);
    }
    setWeekDays(arr);
    setShortWeekDays(shortArr);
  }

  return (
    <>
      <div className="calendar calendar-container calendar-menu--open">
        <div className={`screeningDates-container calendar-menu ${open ? `calendar--open` : ``}`}>
          {
            dates.length > 0 ?
              dates.map((item, index) => (
                index <= 5 ?
                  <Link key={index} to={`/${link}${item.date}`}
                    className={`dateBtn dateBtn${activeDates === item.date ? "--active" : ""}`}>
                    {weekDays[index]}
                  </Link>
                  :
                  null
              ))
              :
              <Link to="#" className="dateBtn dateBtn--active calendar__date--disabled">
                Сегодня
              </Link>
          }
          {
            dates.length > 6 ?
              <button className={`calendar__date calendar__date--select-day ${open ? 'calendar__date--active' : ''}`}
                onClick={open ? handleClose : handleOpen}>Выбрать день
                <span className="calendar__arrow">
                  <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="24" width="24" viewBox="0 0 40 40"
                    className="vertical-align: middle;">
                    <g>
                      <path d="m12.3 13l7.7 7.7 7.7-7.7 2.3 2.4-10 10-10-10z"></path>
                    </g>
                  </svg>
                </span>
              </button>
              : null
          }
        </div>
        {
          open ?
            <div className={`container calendar__days-list`}>
              {dates.length > 0 ?
                dates.map((item, index) => (
                  index >= 6 ?
                    <Link key={index} to={`/${link}${item.date}`}>
                      <button data-is-button-disabled="true" tabIndex={0}
                        className={`calendar-mobile__date 
                        ${shortWeekDays[index].substring(0, 2) === "СБ" || shortWeekDays[index].substring(0, 2) === "ВС"
                            ? "calendar-mobile__date--holiday" : ""}`}>
                        <span className="calendar-mobile__date--day-week">{shortWeekDays[index].substring(0, 2)}</span>
                        <span className={`calendar-mobile__date--day-month ${activeDates === item.date ? "dateBtn--active" : ""}`}>{shortWeekDays[index].substring(2, 4)}</span>
                      </button>
                    </Link>
                    : null
                ))
                : null
              }
            </div>
            : null
        }
      </div >
      <Outlet />
    </>
  );
}

export default ScreeningsDates;