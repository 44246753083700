
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Contacts = () => {
    return (
        <div className="cinema-info-item__contacts">
            <h2 className="cinema-info-item__cinema-name">Нур</h2>
            <div className="cinema-info-item__map">
                <div className="cinema-info-item__map-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.2096891323927!2d72.80344269003324!3d40.51485609774287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38bdae9c91559fa7%3A0x5926a094d8394977!2z0J3Rg9GAIDNEINC60LjQvdC-0YLQtdCw0YLRgA!5e0!3m2!1sru!2skg!4v1703505194614!5m2!1sru!2skg"
                        width="100%" height="300" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>
                </div>
            </div>
            <div className="cinema-info-item__contacts-wrapper">
                <div className="cinema-info-item__contacts-field">
                    <h3 className="cinema-info-item__subtitle">Контакты</h3>
                    <div className="cinema-info-item__contacts-phone">
                        <span>Касса:</span><br />
                        <a className="cinema-info-item__contacts-link" href="tel:0551566444" target="_blank" rel="noopener noreferrer">
                            <span>0551 56 64 44</span><br />
                        </a>
                        <a className="cinema-info-item__contacts-link" href="tel:0771556444" target="_blank" rel="noopener noreferrer">
                            <span>0771 55 64 44</span>
                        </a>
                    </div>
                </div>
                <div className="cinema-info-item__contacts-field">
                    <h3 className="cinema-info-item__subtitle">Адрес</h3>
                    <span>Ош</span>
                    <br />
                    <span>пр. Ленина 212</span>
                </div>
                <div className="cinema-info-item__contacts-field">
                    <h3 className="cinema-info-item__subtitle">Режим работы</h3>
                    <span>Ежедневно с 09:00 до 02:00</span>
                </div>
            </div>
            <div className="cinema-info-item__socials-wrapper">
                <h3 className="cinema-info-item__subtitle">Социальные сети</h3>
                <div className="cinema-info-item__social-links">
                    <a className='social-icon-link tiktok'
                        href='https://www.tiktok.com/@nurcinema_kg'
                        target='_blank' rel="noreferrer"
                        aria-label="tiktok">
                        <FontAwesomeIcon icon={faTiktok} />
                    </a>
                    <a className='social-icon-link instagram'
                        href='https://www.instagram.com/nurcinema_kg'
                        target='_blank' rel="noreferrer"
                        aria-label="instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Contacts;