import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { base_url } from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { MovieSessions, HallPlanInterface, Times, PlaceRequest, GetSessionPricesResponse } from "../../interfaces";
import '../../Plan.css';
import Overlay from "../popup/Overlay";
import { auth } from "../Auth";

const HallPlan = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [movieData, setMovieData] = useState<MovieSessions>(location.state.movie);
    const [hall, setHall] = useState<HallPlanInterface>();
    const [times, setTimes] = useState<[] | Times[]>([]);
    const date = location.pathname.split('/')[2];
    const [rowCounts, setRowCounts] = useState(0);
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [places, setPlaces] = useState<[] | PlaceRequest[]>([]);
    const [placeId, setPlaceId] = useState<number[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isBuyClick, setIsBuyClick] = useState(false);
    const [blockPlace, setBlockPlace] = useState(false);
    const price = movieData != null ? movieData.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100 : 0;

    const divRef = useRef(null);

    let currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();

    useEffect(() => {
        if (location.state == null && location.state.movie) {
            navigate(`/${date}`);
        } else {
            blockPlaces(movieData.time);
            fetchHallPlan(movieData.sessionId);
            fetchSessionTimes();
            setTotalPrice(places.length * price);
        }
    }, [location, places, date]);

    const fetchHallPlan = async (sessionId: number) => {
        await axios({
            url: base_url() + "/api/v1/getHallPlan",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ sessions: sessionId })
        }).then((response) => {
            const data = response.data;
            setHall(data.response.data.session);
            setRowCounts((data.response.data.session.theatre.hall.levels.level.objects.object.length - 2) / 2)
            setIsLoading(false);
            const divElement = divRef.current;
            if (divElement) {
                const { offsetWidth, offsetHeight } = divElement;
                setHeight(offsetHeight);
                setWidth(offsetWidth);
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchHallPlan(movieData.sessionId);
            }
            setIsLoading(false);
        });
    };

    const fetchSessionTimes = async () => {
        const dates: string[] = [];
        if (date !== null && date !== undefined && date !== "") {
            dates.push(date)
        } else {
            dates.push(day + "." + month + "." + year);
        }
        await axios({
            url: base_url() + "/api/v1/getSessionTimes",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ filmId: movieData.filmId, dates: dates })
        }).then((response) => {
            const data = response.data;
            setTimes(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchSessionTimes();
            }
            setIsLoading(false);
        });
    };

    function handleMouseDown(event: React.MouseEvent<HTMLDivElement>) {
        setScrolling(true);
        setStartX(event.pageX - event.currentTarget.offsetLeft);
        setScrollLeft(event.currentTarget.scrollLeft);
    }

    function handleMouseMove(event: React.MouseEvent<HTMLDivElement>) {
        if (!scrolling) return;
        event.preventDefault();
        const x = event.pageX - event.currentTarget.offsetLeft;
        const walk = (x - startX) * 3;
        event.currentTarget.scrollLeft = scrollLeft - walk;
    }

    function handleMouseUp() {
        setScrolling(false);
    }

    const blockPlaces = (time: string) => {
        setBlockPlace(false);
        if (date <= (day + "." + month + "." + year)) {
            // Parse the time string into a Date object
            var timeParts = time.split(':');
            var dateObj = new Date();
            dateObj.setHours(parseInt(timeParts[0], 10));
            dateObj.setMinutes(parseInt(timeParts[1], 10));

            // Subtract 30 minutes
            dateObj.setMinutes(dateObj.getMinutes() - 30);

            if (currentDate >= dateObj) {
                setBlockPlace(true);
            }
        }
    }

    const handleNavigation = (sessionId: number, time: string, hallName: string, hallId: number,
        getSessionPricesResponse: GetSessionPricesResponse) => {
        setPlaces([]);
        setPlaceId([]);

        blockPlaces(time);

        const movie = {
            sessionId: sessionId,
            time: time,
            filmId: movieData.filmId,
            filmName: movieData.filmName,
            age: movieData.age,
            genre: movieData.genre,
            hallName: hallName,
            hallId: hallId,
            levels: movieData.levels,
            formatContent: movieData.formatContent,
            getSessionPricesResponse: getSessionPricesResponse
        }

        setMovieData(movie);
        fetchHallPlan(sessionId);
    };

    const handleChosenPlace = (sessionId: number, row: number, place: number, level: number, pl: number) => {
        const placeObj: PlaceRequest = {
            l: level,
            f: 0,
            r: row,
            p: place,
            id: pl
        }

        if (!placeId.includes(pl)) {
            setPlaceId(p => [...p, pl]);
            setPlaces(places_var => [...places_var, placeObj]);
        } else {
            const updatedPlaceId = placeId.filter(el => el !== pl);
            setPlaceId(updatedPlaceId);

            const updatedPlaces = places.filter(item => item.l !== placeObj.l || item.f !== placeObj.f || item.r !== placeObj.r || item.p !== placeObj.p);
            setPlaces(updatedPlaces);
        }
    }

    const removeChosenPlace = (placeObj: PlaceRequest) => {
        const updatedPlaceId = placeId.filter(el => el !== placeObj.id);
        setPlaceId(updatedPlaceId);

        const updatedPlaces = places.filter(item => item.l !== placeObj.l || item.f !== placeObj.f || item.r !== placeObj.r || item.p !== placeObj.p);
        setPlaces(updatedPlaces);
    }

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    return (
        <div className="popup-inner-content popup-inner-content--hall" ref={divRef}>
            <div className="popup-header">
                <div className="popup-back-icon">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="30" width="30"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                    </svg>
                </div>
                <div className="hall-schema-popup__title">{movieData.filmName}</div>
            </div>
            <div className="hall-schema-popup-panel">
                <div className="hall-schema-popup-panel-col">
                    <span className="hall-schema-popup__date">{date}</span>
                    Кинотеатр Нур
                </div>
            </div>
            <div className="hall-schema-popup">
                <div className="hall-schema-popup-seances" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                    <div className="seance-item">
                        {times.map(time => (
                            <div style={{ display: "inline-block", marginRight: "10px" }} key={time.sessionId}
                                onClick={() => handleNavigation(time.sessionId, time.time, time.hallName, time.hallId,
                                    time.getSessionPricesResponse)} className={`${time.sessionId}`}>
                                <div className="seance-item__container seance-item__container--hall">
                                    <div className="seance-item__container__item">
                                        <div className={`seance-item__time--hall ${movieData.sessionId === time.sessionId ? `seance-item__time--hall-chosen` : ""}`}>
                                            {
                                                movieData.sessionId === time.sessionId ?
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                                                    </svg>
                                                    : null
                                            }
                                            &nbsp;{time.time}
                                        </div>
                                    </div>
                                </div>
                                <div className="seance-item__price">
                                    {
                                        time.getSessionPricesResponse.data.placesTypes.placeType.sum ?
                                            <>
                                                {
                                                    time.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100
                                                }
                                            </> : null
                                    } c
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="logger-overlay logger-overlay__hall"></div>
            <div className="popup-content popup-content--hall">
                <div className="hall-legend_container">
                    <div className="hall-legend">
                        <div className="hall-legend__seance-info">
                            {times.map((time) => (
                                time.sessionId === movieData.sessionId ?
                                    <div key={time.hallId}>
                                        <span>{time.formatContent}</span>
                                        <span>{movieData.age} +</span>
                                        <span>{time.hallName}</span>
                                    </div>
                                    : null
                            ))}
                        </div>
                        <div className="hall-legend__prices">
                            <div className="hall-legend__item">
                                <span className="hall-legend__color" style={{ backgroundColor: "#feb507" }}></span>
                                {times.map((time) => (
                                    time.sessionId === movieData.sessionId && time.getSessionPricesResponse.data.placesTypes.placeType.sum ? (
                                        <span key={time.hallId}>
                                            {time.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100} c
                                        </span>
                                    ) : null
                                ))}
                            </div>
                            <div className="hall-legend__item">
                                <span className="hall-legend__color hall-legend__color--disabled"></span>
                                <span>Занято</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hall-schema-seats-container">
                    <div className="scalable__children-wrapper">
                        <div className="scalable__container">
                            <div className="hall-schema-container">
                                <div className="hall-schema"
                                    style={{
                                        width: hall?.theatre.hall.levels.level.places.width + "px",
                                        height: hall?.theatre.hall.levels.level.places.height + "px"
                                    }}>
                                    <div className="hall-schema__screen" style={{
                                        height: "75px",
                                        top: (rowCounts * 52) + "px"
                                    }}></div>
                                    {/* <div className="hall-sector"></div> */}
                                    {/* {hall?.theatre.hall.levels.level.places.place.map(place => (
                                            <div className="hall-schema-row" key={place.id}>{place.row}</div>
                                        ))} */}
                                    {hall?.theatre.hall.levels.level.places.place.map(place => (
                                        <div key={`${place.place}${place.row}${place.id}`}
                                            className={`hall-schema-seat__tooltip hall-schema-seat${placeId.includes(place.id) ? ` hall-schema-seat--chosen`
                                                : place.status && place.status !== 1 ? " hall-schema-seat--not-available" : blockPlace ? " hall-schema-seat--not-available" : ""}`}
                                            style={{
                                                left: place.x + "px",
                                                top: place.y + "px",
                                                height: place.height + "px",
                                                width: place.width + "px",
                                                backgroundColor: "rgb(254, 181, 7)"
                                            }}
                                            onClick={() =>
                                                places.length < 4 || placeId.includes(place.id) ?
                                                    handleChosenPlace(movieData.sessionId, place.row, place.place, hall.theatre.hall.levels.level.id, place.id)
                                                    : null
                                            }
                                        >
                                            <div className="hall-schema-seat__place" style={{ borderColor: "rgb(254, 181, 7)" }}>{place.place}</div>
                                            <div className="hall-schema-seat__tooltip-content">
                                                <span>{place.row} ряд, {place.place} место</span>
                                                {times.map((time) => (
                                                    time.time === movieData.time ?
                                                        <span key={time.hallId}>
                                                            {
                                                                time.getSessionPricesResponse.data.placesTypes.placeType.sum ?
                                                                    time.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100 + " c"
                                                                    : null
                                                            }
                                                        </span>
                                                        : null
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className="hall-schema-row">1</div>
                                                    <div className="hall-schema-row">2</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-footer">
                <div className="popup-footer-col hall-schema__footer">
                    <div className="ticket-card__container">
                        {
                            places.length > 0 ?
                                places.map((item) => (
                                    <div className="ticket-card__item ticket-card__item-transition-enter-done" key={`${item.r}${item.p}`}>
                                        <div className="ticket-card__row-place">Ряд {item.r}, Место {item.p}</div>
                                        <div className="ticket-card__ticket-type"></div>
                                        <div className="ticket-card__price">
                                            <div className="ticket-card__legend" style={{ backgroundColor: "rgb(254, 181, 7)" }} />
                                            {times.map((time) => (
                                                time.time === movieData.time && time.getSessionPricesResponse.data.placesTypes.placeType.sum ?
                                                    (() => {
                                                        return time.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100 + " c";
                                                    })()
                                                    : null
                                            ))}
                                        </div>
                                        <img className="ticket-card__close-button" src="/image/close-round.svg" alt="img"
                                            onClick={() => removeChosenPlace(item)} />
                                    </div>
                                ))
                                : null
                        }
                    </div>
                    {/* {
                        places.length === 0 ?
                            <button className="button button--primary disabled" type="button">Места не выбраны</button>
                            :
                            <button className="button button--primary" type="button" onClick={() => setIsBuyClick(true)}>Купить {totalPrice} c</button>
                    } */}
                </div>
            </div>
            <div className="app-footer g-mobile-hide--flex">
                <span className="app-footer__block">
                    <span className="margin-right: 0.44em;">&nbsp;</span>
                </span>
            </div>
            {
                isBuyClick ?
                    <Overlay totalPrice={totalPrice} places={places} movie={movieData} date={date} handleClose={() => setIsBuyClick(false)} />
                    :
                    null
            }
        </div>
    );
}

export default HallPlan;
