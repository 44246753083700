import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { Movies, Days, GetSessionPricesResponse } from "../../interfaces/index";
import YouTube from 'react-youtube';
import { auth } from "../Auth";

const Release = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let currentDate = new Date();
    const date = location.pathname.split('/')[2];
    const filmId = location.pathname.split('/')[3];
    let months = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июн.', 'июл.', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'];
    let weekDay = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    let shortWeekDay = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
    const [isLoading, setIsLoading] = useState(true);
    const [movie, setMovie] = useState<[] | Movies[]>([]);
    const [activeDates, setActiveDates] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [dates, setDates] = useState<[] | Days[]>([]);
    const [weekDays, setWeekDays] = useState<[] | string[]>([]);
    const [shortWeekDays, setShortWeekDays] = useState<[] | string[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        fetchMovies(date);
    }, [location]);

    const fetchMovies = async (date: string) => {
        const dates: string[] = [];
        dates.push(date)
        await axios({
            url: base_url() + `/api/v1/getMovies?dates=${dates}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            if (data.status === 0) {
                setMovie(data.response);
                setIsLoading(false);
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchMovies(date);
            }
            setIsLoading(false);
        });
    };

    const handleDateClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setFormattedDate('');
        setActiveDates(event.currentTarget.id);
    };

    useEffect(() => {
        setFormattedDate(date);

        const fetchDates = async () => {
            await axios({
                url: base_url() + "/api/v1/getDaysByFilmId",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                data: JSON.stringify({ filmId: filmId })
            }).then((response) => {
                const data = response.data;
                if (data.status === 0) {
                    listOfDates(data.response);
                    setDates(data.response);
                }
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchDates();
                }
            });
        };

        fetchDates();
    }, []);

    const listOfDates = (data: Days[]) => {
        const arr: string[] = [];
        const shortArr: string[] = [];

        for (let i = 0; i < data.length; i++) {
            let el = data[i];
            const dateParts = el.date.split(".");
            const year = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[0]);
            const date = new Date(year, month, day);

            const tomorrow = new Date(currentDate);
            tomorrow.setDate(tomorrow.getDate() + 1);

            const tomorrowDate = tomorrow.toLocaleDateString();

            if (currentDate.toLocaleDateString() === date.toLocaleDateString()) {
                arr.push("Сегодня");
            } else if (tomorrowDate === date.toLocaleDateString()) {
                arr.push("Завтра");
            } else {
                let dayStr = weekDay[date.getDay()] + ', ' + (date.getDate()) + ' ' + months[date.getMonth()];
                arr.push(dayStr);
            }

            let shortDayStr = shortWeekDay[date.getDay()] + (date.getDate());
            shortArr.push(shortDayStr);
        }
        setWeekDays(arr);
        setShortWeekDays(shortArr);
    }

    const handleNavigation = (sessionId: number, time: string, filmId: number, filmName: string, age: number, genre: string, hallName: string,
        hallId: number, levels: number, formatContent: string, getSessionPricesResponse: GetSessionPricesResponse) => {
        const movie = {
            sessionId: sessionId,
            time: time,
            filmId: filmId,
            filmName: filmName,
            age: age,
            genre: genre,
            hallName: hallName,
            hallId: hallId,
            levels: levels,
            formatContent: formatContent,
            getSessionPricesResponse: getSessionPricesResponse
        }
        navigate(`/hallplan/${date}`, { state: { movie } })
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    return (
        <>
            <div className='main'>
                {movie.map(item => (
                    (item.times.length > 0 && filmId === item.filmId + '') ?
                        <div key={item.filmId}>
                            <div className="breadcrumbs">
                                <div className="breadcrumbs-container container">
                                    <a className="breadcrumbs__link" href="/">Расписание залов</a>
                                    <span className="breadcrumbs__divider">»</span>
                                    <span className="breadcrumbs__page">{item.filmName}</span>
                                </div>
                            </div>
                            <div className="release">
                                <div className="container release-container">
                                    <div className="release-left">
                                        <div className="release-poster ">
                                            <div className="release-poster__img-wrapper">
                                                <img src={`https://api.nurcinema.kg/files/${item.picture.fileName}${item.picture.fileExt}`} className="release-poster__img" alt="Постер релиза" />
                                            </div>
                                            <div className="release__age">{item.age}+</div>
                                            <div className="release__badge">
                                                {/* <div className="release__premiere">Премьера</div> */}
                                            </div>
                                        </div>
                                        <div className="release-rating">
                                        </div>
                                    </div>
                                    <div className="release-right">
                                        <div className="release-description">
                                            <div className="release__genre">{item.genre}</div>
                                            <div className="release__title">{item.filmName}</div>
                                        </div>
                                        <div className="release-schedule-wrap">
                                            <div className="calendar-menu">
                                                <div className="calendar calendar-container calendar-menu--open calendar--release-page">
                                                    <div className={`screeningDates-container calendar-menu ${open ? `calendar--open` : ``}`}>
                                                        {
                                                            dates.length > 0 ?
                                                                dates.map((date, index) => (
                                                                    index <= 5 ?
                                                                        <Link key={date.date} to={`/release/${date.date}/${item.filmId}`} id={date.date} onClick={handleDateClick}
                                                                            className={`dateBtn dateBtn${activeDates === date.date || date.date === formattedDate ? "--active" : ""}`}>
                                                                            {weekDays[index]}
                                                                        </Link>
                                                                        :
                                                                        null
                                                                ))
                                                                :
                                                                <Link to="#" className="dateBtn dateBtn--active calendar__date--disabled">
                                                                    Сегодня
                                                                </Link>
                                                        }
                                                        {
                                                            dates.length < 5 ?
                                                                <button className={`calendar__date calendar__date--select-day ${open ? 'calendar__date--active' : ''}`}
                                                                    onClick={open ? handleClose : handleOpen}>Выбрать день
                                                                    <span className="calendar__arrow">
                                                                        <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="24" width="24" viewBox="0 0 40 40"
                                                                            className="vertical-align: middle;">
                                                                            <g>
                                                                                <path d="m12.3 13l7.7 7.7 7.7-7.7 2.3 2.4-10 10-10-10z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        open ?
                                                            <div className="calendar__days-list">
                                                                {dates.length > 0 ?
                                                                    dates.map((item, index) => (
                                                                        index >= 6 ?
                                                                            <Link key={index} to={`/release/${item.date}/${filmId}`} id={`${item.date}`} onClick={handleDateClick}>
                                                                                <button data-is-button-disabled="true" tabIndex={0}
                                                                                    className={`calendar-mobile__date ${shortWeekDays[index].substring(0, 2) === "СБ" || shortWeekDays[index].substring(0, 2) === "ВС"
                                                                                        ? "calendar-mobile__date--holiday" : ""}`}>
                                                                                    <span className="calendar-mobile__date--day-week">{shortWeekDays[index].substring(0, 2)}</span>
                                                                                    <span className="calendar-mobile__date--day-month">{shortWeekDays[index].substring(2, 4)}</span>
                                                                                </button>
                                                                            </Link>
                                                                            : null
                                                                    ))
                                                                    : null
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="release-schedule">
                                                <div className="release-schedule__list">
                                                    <div className="release-schedule__item">
                                                        {item.times.map(time => (
                                                            <div className="seance-item" key={time.sessionId}
                                                                onClick={(e) => handleNavigation(time.sessionId, time.time, item.filmId, item.filmName, item.age,
                                                                    item.genre, time.hallName, time.hallId, time.levels, time.formatContent, time.getSessionPricesResponse)}>
                                                                <div className="seance-item__container">
                                                                    <div className="seance-item__item">
                                                                        <div className="seance-item__time">{time.time}</div>
                                                                        <div className="seance-item__wrapper">
                                                                            <div className="seance-item__description ">
                                                                                <span>{time.formatContent}</span>
                                                                                <span className="seance-item--space"></span>
                                                                                <span>
                                                                                    {
                                                                                        time.getSessionPricesResponse.data.placesTypes.placeType.sum ?
                                                                                            time.getSessionPricesResponse.data.placesTypes.placeType.sum.sum / 100 : null
                                                                                    } c
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {time.isHallVip ?
                                                                        <div className="seance-item__vip"></div>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div className="seance-item__hall">{time.hallName}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {item.picture.fileName !== undefined || item.picture.fileName !== "" ?
                                            <div>
                                                <h2 className="releases-list__title">Трейлер</h2>
                                                <div className="release-trailer-list">
                                                    <video width="440" height="200" controls>
                                                        <source src={`https://api.nurcinema.kg/files/${item.picture.fileName}.mp4`} type="video/mp4"
                                                            className="release-trailer-item" />
                                                        <img src="" className="release-trailer-item__poster" alt="" />
                                                        <div className="release-trailer-item__play">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" className="icon icon-white">
                                                                <path fill="#FFF" fillRule="evenodd" stroke="#FFF" d="M.5 25V1l21 12z"></path>
                                                            </svg>
                                                        </div>
                                                    </video>
                                                </div>
                                            </div>
                                            : null} */}
                                        {
                                            item.youtubeId ?
                                                <YouTube videoId={item.youtubeId} opts={{ width: "300", height: "150" }} />
                                                : null
                                        }
                                        <h2 className="releases-list__title">О фильме</h2>
                                        <div className="release__info">
                                            <div className="release__info-item">
                                                <span className="release__info-item__name">Режиссёр</span>
                                                <span className="release__info-item__description">{item.director}</span>
                                            </div>
                                            <div className="release__info-item">
                                                <span className="release__info-item__name">В ролях</span>
                                                <span className="release__info-item__description">{item.actors}</span>
                                            </div>
                                            <div className="release__info-item">
                                                <span className="release__info-item__name">Хронометраж</span>
                                                <span className="release__info-item__description">{item.duration.duration}</span>
                                            </div>
                                            <div className="release__info-item">
                                                <span className="release__info-item__name">Страна</span>
                                                <span className="release__info-item__description">{item.country}</span>
                                            </div>
                                            <div className="release__info-item">
                                                <span className="release__info-item__name">Год</span>
                                                <span className="release__info-item__description">{ }</span>
                                            </div>
                                        </div>
                                        <div className="release__text">
                                            <div className="release__info-item__name">Описание</div>
                                            {item.remark}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                ))}
            </div>
        </>
    )
}
export default Release;
