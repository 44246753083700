import { useRef, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { PlaceRequest } from "../../interfaces";
import axios from "axios";
import { base_url } from "../Base_url";
import { auth } from "../Auth";
import { useNavigate } from "react-router-dom";

const Overlay = (props: any) => {
    const divRef = useRef<HTMLDivElement>(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
            props.handleClose();
        }
    };

    useEffect(() => {
        // document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            amount: props.totalPrice,
            sessions: props.movie.sessionId
        })
    }, [form]);

    const onFinish = async (values: any) => {
        let data = {
            sessions: props.movie.sessionId,
            amount: props.totalPrice,
            email: values.email,
            phone: values.phone,
            places: props.places,
            filmName: props.movie.filmName,
            hallName: props.movie.hallName,
            dateTime: props.date + " " + props.movie.time
        }

        fetchPay(data);

        form.resetFields();
    };

    const fetchPay = (data: any) => {
        axios({
            url: base_url() + `/api/v1/saleReservationPayment`,
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json"
            },
            data: JSON.stringify(data),
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.status === 0) {
                    form.resetFields();
                    var x = window.open("", "_self");
                    if (x) {
                        x.document.open();
                        x.document.write(response.data.response.result);
                        x.document.close();
                    }
                } else {
                    navigate("/error");
                }
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchPay(data);
            }
        });
    }

    return (
        <div ref={divRef}>
            <div className="overlay overlay-enter-done"></div>
            <div className="overlay-order overlay-sidebar-enter-done">
                <div className="overlay-header">
                    <div className="overlay-header__info">
                        <div className="overlay-header__info-text">Введите данные для покупки</div>
                        <h2 className="text order-subtitle">
                            Покупка будет отменена через 15 минут
                        </h2>
                    </div>
                </div>
                <div className="overlay-content">
                    <div className="order-email-wrapper">
                        <div className="order-email">
                            <div className="order-info__release-wrapper">
                                <h2 className="text order-subtitle">{props.movie.filmName}</h2>
                                <div className="text text--small order-info__release-cinema">Кинотеатр Нур</div>
                                <div className="text text--small order-info__release-hall">{props.movie.hallName}</div>
                                <div className="text text--small order-info__release-datetime">{props.date} {props.movie.time}</div>
                            </div>
                            <div className="order-total">
                                <h2 className="order-total__subtitle order-subtitle">Билеты</h2>
                                <div className="order-total__table-container">
                                    {props.places.map((item: PlaceRequest, index: number) => (
                                        <p key={index}>Ряд {item.r}, место {item.p}</p>
                                    ))}
                                </div>
                            </div>
                            <br />
                            <Form form={form} layout="vertical" onFinish={onFinish}>
                                <div className="input-container">
                                    <div className="input-content">
                                        <Form.Item label="Ваш email" name="email" className="input ym-disable-keys input--correct"
                                            rules={[{
                                                required: true,
                                                message: 'Введите электронную почту',
                                                validator: (_, value) =>
                                                    value !== undefined ? Promise.resolve() : Promise.reject()
                                            }]}>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="input__info">На этот адрес мы отправим билеты</div>
                                </div>
                                <div className="order-phone">
                                    <div className="input-container">
                                        <div className="input-content">
                                            <Form.Item label="Ваш номер телефона" name="phone" rules={[{
                                                required: true,
                                                message: 'Введите номер телефона',
                                                validator: (_, value) =>
                                                    value !== undefined ? Promise.resolve() : Promise.reject()
                                            }]}
                                                className="input ym-disable-keys input--correct">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <Form.Item noStyle name="amount">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item noStyle name="sessions">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item noStyle name="filmName">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="button button__sidebar button--primary">
                                        Оплатить
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Overlay;