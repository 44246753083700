const About = () => {
    return (
        <div className="main">
            <div className="content cinema">
                <div className="container cinema-container">
                    <div className="cinema-info cinema-about-info">
                        <div className="cinema__title">О кинотеатре</div>
                        <p>
                            Кинотеатр “Нур" является первым цифровым кинотеатром в городе Ош, кинотеатр оснащен самым современным кинопроекционным
                            оборудованием в индустрии от мирового лидера систем визуализации Christie (Канада), звуком от Dolby Digital (США). Все залы
                            максимально удобны, с увеличенной шириной проходов между рядами кресел. А кресла для каждого зала мы приобрели у одного из
                            лучших мировых производителей.Киноэкраны демонстрируют высокие характеристики качества изображения: широкие углы обзора,
                            контрастность и яркость изображения. Благодаря функциональным и комфортабельным креслам, каждый зритель имеет возможность
                            полностью погрузиться в мир кино. Кинотеатр «Нур» имеет в своем распоряжении 6 кинозалов: 1 Зал – 275 мест, 2 Зал – 148 мест
                            и 4 зала для показа отечественных фильмов. В обоих залах есть возможность показа фильмов в 3D формате. В кинобаре гостям
                            предлагается большой выбор напитков, десертов, закусок и попкорна. Скоротать время перед сеансом можно в уютной атмосфере на
                            удобных диванах, расположенных в фойе кинотеатра. На территории кинотеатра работает кафе Этно. Меню кафе состоит из блюд
                            международной кухни.
                        </p>
                        <br />
                        <div className="se-component se-image-container __se__float-none">
                            <img className="lazyloaded" alt=""
                                src="/image/PhotoLab2.jpg" />
                        </div><br /><br />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About;